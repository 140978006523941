import React from "react";

const styles = {
  body: { backgroundColor: "aqua" },
  cardWidth: { width: "100%" },
};
const Reviews = () => {
  return (
    <div className="reviews-section p-5 d-flex" style={styles.body}>
      <section className="container">
        <div className="reviews">
          <h2 className="section-heading mb-3">Shop Reports &amp; Reviews</h2>
          <div className="row justify-content-around">
            <div className="col-6 col-lg-3 justify-content-start">
              <div className="card" style={styles.cardWidth}>
                <div id="carouselExample01" className="carousel slide">
                  <div className="carousel-inner card-img-top">
                    <div className="carousel-item active">
                      <img
                        src="./images/box01.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/box02.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/box03.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample01"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample01"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="card-body">
                  <p className="card-text text-center">
                    Jan - Dec 2022 Sales Reports
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 justify-content-center">
              <div className="card" style={styles.cardWidth}>
                <div id="carouselExample02" className="carousel slide">
                  <div className="carousel-inner card-img-top">
                    <div className="carousel-item active">
                      <img
                        src="./images/lala01.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/lala02.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/lala03.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample02"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample02"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="card-body">
                  <p className="card-text text-center">Rider Pick-Up Proof</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 justify-content-end">
              <div className="card" style={styles.cardWidth}>
                <div id="carouselExample03" className="carousel slide">
                  <div className="carousel-inner card-img-top">
                    <div className="carousel-item active">
                      <img
                        src="./images/feedback.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/feedback.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="./images/feedback.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample03"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample03"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="card-body">
                  <p className="card-text text-center">Customer Feedback</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Reviews;
