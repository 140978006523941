import React from "react";
import "./Cart.css";

const styles = {
  Imgwidth: { width: "120px" },
  fontweight: { fontweight: 500 },
  width: { width: "50px" },
  cardborder: { borderradius: "16px" },
};

const Cart = () => {
  return (
    <section className="h-custom">
      <div className="container py-5">
        <div className="row d-flex justify-content-center p-5">
          <div className="col">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="h5">
                      Shopping Bag
                    </th>
                    <th scope="col">Size</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <img
                          src={null}
                          className="img-fluid rounded-3"
                          style={styles.Imgwidth}
                          alt="Shoes"
                        />
                        <div className="flex-column ms-4">
                          <p className="mb-2">NIKE</p>
                          <p className="mb-0">Airmax 2</p>
                        </div>
                      </div>
                    </th>
                    <td className="align-middle">
                      <p className="mb-0" style={styles.fontweight}>
                        M 10
                      </p>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex flex-row">
                        <button
                          className="btn btn-link px-2"
                          onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                        >
                          <i className="fas fa-minus"></i>
                        </button>

                        <input
                          id="form1"
                          min="0"
                          name="quantity"
                          value="2"
                          type="number"
                          className="form-control form-control-sm"
                          style={styles.width}
                        />

                        <button
                          className="btn btn-link px-2"
                          onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td className="align-middle">
                      <p className="mb-0" style={styles.fontweight}>
                        ₱3,650.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="border-bottom-0">
                      <div className="d-flex align-items-center">
                        <img
                          src={null}
                          className="img-fluid rounded-3"
                          style={styles.Imgwidth}
                          alt="Shoes"
                        />
                        <div className="flex-column ms-4">
                          <p className="mb-2">Converse</p>
                          <p className="mb-0">All-Start Chuck Taylor 70s</p>
                        </div>
                      </div>
                    </th>
                    <td className="align-middle border-bottom-0">
                      <p className="mb-0" style={styles.fontweight}>
                        M 8
                      </p>
                    </td>
                    <td className="align-middle border-bottom-0">
                      <div className="d-flex flex-row">
                        <button
                          className="btn btn-link px-2"
                          onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                        >
                          <i className="fas fa-minus"></i>
                        </button>

                        <input
                          id="form1"
                          min="0"
                          name="quantity"
                          value="1"
                          type="number"
                          className="form-control form-control-sm"
                          style={styles.width}
                        />

                        <button
                          className="btn btn-link px-2"
                          onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td className="align-middle border-bottom-0">
                      <p className="mb-0" style={styles.fontweight}>
                        ₱3,650.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="card shadow-2-strong mb-5 mb-lg-0"
              style={styles.cardborder}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
