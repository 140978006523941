const Api = {
  ENDPOINT:
    process.env.NODE_ENV === "production"
      ? "https://grp11api.webpark.tech"
      : "http://localhost:3002",
  MODE: "cors",
  HEADERS: {
    "content-type": "application/json",
  },
};

export { Api };
