import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <div className="hero-image_text-wrapper text-center position-relative mt-3">
        <div className="hero-image_title-wrapper">
          <h1 className="hero-image_title hero-image_title-first section-heading">
            ADIDAS DON ISSUE 3 "PURPLE"
          </h1>
        </div>
        <Link to="footwear" style={{ textDecoration: "none" }}>
          <button
            href="shop_now.html"
            className="hero-image_button button button-primary"
          >
            <span>See More</span>
          </button>
        </Link>
      </div>
      <Link to="footwear" style={{ textDecoration: "none" }}>
        <div className="shopify-section mx-2">
          <section className="container-fluid hero-image">
            <div className="hero-image_image-wrapper">
              <div className="hero-image_image position-relative">
                <button href="shop_now.html">
                  <img
                    style={{
                      width: "185vh",
                      height: "80vh",
                    }}
                    src="./images/don.jpg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </section>
        </div>
      </Link>
    </>
  );
};
export default Banner;
