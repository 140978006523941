import React from "react";

function Contact() {
  return (
    <div className="login-section" style={{ height: "80vh" }}>
      <section className="container d-flex justify-content-center">
        <div className="p-5 position-relative align-items-center">
          <div className>
            <h4 className="section-heading mb-3">Send Us A Message</h4>
          </div>
          <form className="reg">
            <div className="mb-3">
              <label htmlFor="fname" className="form-label">
                Full Name
              </label>
              <input
                type="name"
                className="form-control email"
                id="name"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lname" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control email"
                id="email"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Contact
              </label>
              <input
                type="name"
                className="form-control email"
                id="name"
                placeholder="Contact"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Send a Message
              </label>
              <input
                type="name"
                className="form-control email"
                style={{ height: "20vh" }}
                id="name"
                placeholder="Send your Message"
              />
            </div>
            <button type="submit" className="login button button-primary">
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
