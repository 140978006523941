import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="shadow sticky-top top-0 z-50">
      <nav className="navbar navbar-expand-md">
        <div className="container">
          <Link to="/">
            <button href="index.html" className="navbar-brand border-0">
              <img
                className="logo rounded-circle "
                src="./images/logo.jpg"
                alt=""
              />
            </button>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto mb-md-0 justify-content-center ">
              <li className="nav-item dropdown ">
                <button
                  className="nav-link dropdown-toggle border-0"
                  href="#"
                  role="menuitem"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Brands
                </button>
                <ul className="dropdown-menu">
                  <Link to="nike" style={{ textDecoration: "none" }}>
                    <li>
                      <button className="dropdown-item " href="nike.html">
                        Nike
                      </button>
                    </li>
                  </Link>
                  <Link to="adidas" style={{ textDecoration: "none" }}>
                    <li>
                      <button className="dropdown-item" href="adidas.html">
                        Adidas
                      </button>
                    </li>
                  </Link>
                  <Link to="converse" style={{ textDecoration: "none" }}>
                    <li>
                      <button className="dropdown-item" href="converse.html">
                        Converse
                      </button>
                    </li>
                  </Link>
                  <Link to="newbalance" style={{ textDecoration: "none" }}>
                    <li>
                      <button className="dropdown-item" href="newbalance.html">
                        New Balance
                      </button>
                    </li>
                  </Link>
                </ul>
              </li>
              <Link to="footwear" style={{ textDecoration: "none" }}>
                <li className="nav-item">
                  <button className="nav-link border-0" href="footwear.html">
                    Footwear
                  </button>
                </li>
              </Link>
              <Link to="accessories" style={{ textDecoration: "none" }}>
                <li className="nav-item">
                  <button className="nav-link border-0" href="accessories.html">
                    Accessories
                  </button>
                </li>
              </Link>
              <Link to="dashboard" style={{ textDecoration: "none" }}>
                <li className="nav-item">
                  <button className="nav-link border-0" href="accessories.html">
                    Admin
                  </button>
                </li>
              </Link>
            </ul>
          </div>
          <div className="d-flex">
            <form className="d-flex" role="search">
              <input
                className="form-control search-icon"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline fa-lg" type="submit">
                <ion-icon name="search-outline" />
              </button>
            </form>
            <div className="icons d-flex align-items-center fa-lg">
              <Link to="cart">
                <button href="#">
                  <ion-icon name="cart-outline" className="icon border-0 " />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
