import React from "react";
import "./Faqs.css";

function Faqs() {
  return (
    <>
      <section className="py-5 h-custom">
        <h3 className="text-center mb-4 pb-2 text-primary fw-bold">FAQs</h3>
        <p className="text-center mb-5">How can we help you?</p>

        <div className="row mx-auto">
          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="far fa-paper-plane text-primary pe-2"></i> Is it
              safe to shop online?
            </h6>
            <p>
              <strong>
                <u>First, </u>
              </strong>
              we highly recommend you have a strong password for your shopping
              website
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="fas fa-pen-alt text-primary pe-2"></i>Are the brands
              authentic?
            </h6>
            <p>
              <strong>
                <u>
                  We ensure that our brands are authentic and sourced from
                  trust-worthy suppliers.
                </u>
              </strong>{" "}
              If you are buying from websites that you are not familiar with,
              research the products they offer. Also, if the price for a
              well-known brand is ‘too good to be true’ then talk to the website
              and do exercise caution.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="fas fa-user text-primary pe-2"></i> How to Order?
            </h6>
            <p>
              To start shopping, click on the product page. Once you’ve added
              your items in the cart and you’re ready to check out, click on
              view cart button. Proceed to check out and review your order and
              choose your handling shipping destination.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="fas fa-rocket text-primary pe-2"></i> Can I change/
              Cancel my order?
            </h6>
            <p>
              Once an order has been placed, we can’t edit the address you’ve
              entered or any items within the order. If our warehouse is already
              packing up your shipment, we cannot cancel or change it in anyway.
              Yes.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="fas fa-home text-primary pe-2"></i> How can i know
              if my order has been confirmed
            </h6>
            <p>
              <strong>
                <u>Here's what you neded to do</u>.
              </strong>{" "}
              Go to the billing section of your dashboard and update your
              payment information.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary">
              <i className="fas fa-book-open text-primary pe-2"></i>How long
              does delivery take and what are my shipping costs
            </h6>
            <p>
              Standard delivery within Metro Manila will take 2-3 business days
              upon confirmation of payment. For delivery outside Metro Manila,
              it will take 3-5 business days upon confirmation of payment.
              Delivery fee is automatically added to your guaranteed order
              total. Metro Manila rate has a standard fee of 150 pesos per
              package and provincial rates are 250 pesos per package.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqs;
