import React from "react";
import Header from "./components/Header";
import Home from "./pages/HomePage/Home";
import Faqs from "./components/FooterPage/FAQS";
import Contact from "./components/FooterPage/ContactPage/Contact";
import Footer from "./components/FooterPage/Footer";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Product from "./components/Product";
import Footwear from "./components/ProductPage/Footwear";
import Accessories from "./components/ProductPage/Accessories";
import Dashboard from "./admin/Dashboard";
import Shoes from "./Shoes/Shoes";
import Cart from "./components/ShoppingCart/Cart";
import Nike from "./components/ProductPage/Brands/Nike";
import Adidas from "./components/ProductPage/Brands/Adidas";
import Converse from "./components/ProductPage/Brands/Converse";
import NewBalance from "./components/ProductPage/Brands/NewBalance";

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
      {
        path: "nike",
        element: <Nike />,
      },
      {
        path: "adidas",
        element: <Adidas />,
      },
      {
        path: "converse",
        element: <Converse />,
      },
      {
        path: "newbalance",
        element: <NewBalance />,
      },
      {
        path: "shoes",
        element: <Shoes />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "footwear",
        element: <Footwear />,
      },
      {
        path: "accessories",
        element: <Accessories />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
      {
        path: "contactus",
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
