import React from "react";

const Footwear = () => {
  return (
    <main id="mainContent">
      <article>
        <div className="shopify-section">
          <section className="container new-arrivals">
            <div className="new-arrivals_wrap py-5 px-3">
              <h2 className="section-heading mb-3">Footwear</h2>
              <div className="new-arrivals_products">
                <div className="row">
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/don-white.png" alt="" />
                      </a>
                      <span className="product-item__vendor">ADIDAS</span>
                      <span className="product-item__title mt-2">
                        Don Issue 3 "White Camou"
                      </span>
                      <span className="price mb-2">₱3,650.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/dame-mint.jpg" alt="" />
                      </a>
                      <span className="product-item__vendor">ADIDAS</span>
                      <span className="product-item__title mt-2">
                        Dame 7 Extply "Black Clear Mint"
                      </span>
                      <span className="price mb-2">₱3,650.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/nike-white.jpg" alt="" />
                      </a>
                      <span className="product-item__vendor">NIKE</span>
                      <span className="product-item__title mt-2">
                        Nike Airmax Impact 2
                      </span>
                      <span className="price mb-2">₱3,650.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/nike-immo.jpg" alt="" />
                      </a>
                      <span className="product-item__vendor">NIKE</span>
                      <span className="product-item__title mt-2">
                        Nike Giannis Immortality 2
                      </span>
                      <span className="price mb-2">₱3,650.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/cons-jam.jpg" alt="" />
                      </a>
                      <span className="product-item__vendor">CONVERSE</span>
                      <span className="product-item__title mt-2">
                        Converse BB Jet "NBA Jam"
                      </span>
                      <span className="price mb-2">₱3,780.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/nb-pink.png" alt="" />
                      </a>
                      <span className="product-item__vendor">NEW BALANCE</span>
                      <span className="product-item__title mt-2">
                        New Balance 2WXYZ "Pink"
                      </span>
                      <span className="price mb-2">₱3,780.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/nb-yellow.png" alt="" />
                      </a>
                      <span className="product-item__vendor">NEW BALANCE</span>
                      <span className="product-item__title mt-2">
                        New Balance 2WXYZ "Yellow"
                      </span>
                      <span className="price mb-2">₱3,780.00</span>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="product-item">
                      <a href="#">
                        <img src="./images/chuck-70s.png" alt="" />
                      </a>
                      <span className="product-item__vendor">CONVERSE</span>
                      <span className="product-item__title mt-2">
                        Converse Chuck Taylor 70s
                      </span>
                      <span className="price mb-2">₱3,780.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
};

export default Footwear;
