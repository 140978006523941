import React from "react";
import { Link } from "react-router-dom";

const styles = {
  textcolor: { color: "black" },
};

function Product({ data, index }) {
  return (
    <div className=" col-6 col-lg-3">
      <div className="product-item ">
        <Link to="shoes" style={{ textDecoration: "none" }}>
          <button href="">
            <img className="grid grid-cols-4 gap-5" src={data.img} alt="" />
          </button>
          <span className="product-item__vendor" style={styles.textcolor}>
            {data.title}
          </span>
          <span className="product-item__title mt-2" style={styles.textcolor}>
            {data.desc}
          </span>
          <span className="price mb-2 flex transform " style={styles.textcolor}>
            ₱{data.price}.00
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Product;
