import React from "react";

const Accessories = () => {
  return (
    <>
      <div className="shopify-section">
        <section className="container new-arrivals">
          <div className="new-arrivals_wrap py-4 px-3" />
          <h2 className="section-heading mb-3">Accessories / Jewelries</h2>
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
          >
            <div className="carousel-inner mb-4">
              <div className="carousel-item active">
                <img
                  src="./images/red-smile.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block product-item">
                  <h5>RED SMILE</h5>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="./images/blue-eye.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block product-item">
                  <h5>BLUE EYE</h5>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="./images/blue-h.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block product-item">
                  <h5>SHINY HEART</h5>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleFade"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleFade"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default Accessories;
