import React from "react";

function Shoes() {
  return (
    <main id="mainContent">
      <article>
        <div className="shopify-section">
          <section className="container new-arrivals">
            <div className="new-arrivals_wrap py-5 px-3">
              <h2 className="section-heading mb-3">
                Adidas / Don Issue 3 "White Camou"
              </h2>
              <div className="row">
                <div className="col-md-7 pr-0">
                  <div className="product-gallery">
                    <div className="gallery-main">
                      <img src="./images/don-white.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-3 mt-lg-0">
                  <div className>
                    <span className="product-item__vendor">ADIDAS</span>
                    <span className="product-item__title mt-2">
                      Don Issue 3 "White Camou"
                    </span>
                    <span className="d-flex mb-2">
                      When the game is on the line, Donovan Mithell ensures the
                      ball finds his hands.
                    </span>
                    <span className="price mb-2 d-flex mb-3">₱3,650.00</span>
                    <span className="choose-size mb-">Choose a size:</span>
                    <div className="product-size form-check">
                      <ul className="size-option mb-4">
                        <li className="size position-relative">
                          <label htmlFor="size8">M 8</label>
                          <input type="checkbox" name="size8" id="size8" />
                        </li>
                        <li className="size position-relative">
                          <label htmlFor="size9">M 9</label>
                          <input type="checkbox" name="size9" id="size9" />
                        </li>
                        <li className="size position-relative">
                          <label htmlFor="size10">M 10</label>
                          <input type="checkbox" name="size10" id="size10" />
                        </li>
                        <li className="size position-relative">
                          <label htmlFor="size11">M 11</label>
                          <input type="checkbox" name="size11" id="size11" />
                        </li>
                        <li className="size position-relative">
                          <label htmlFor="size12">M 12</label>
                          <input type="checkbox" name="size12" id="size12" />
                        </li>
                      </ul>
                      <div>
                        <button className="cart button">
                          <span>Add to Cart</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}

export default Shoes;
