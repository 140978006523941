import React from "react";
import Banner from "../../components/BannerPage/Banner";
import Product from "../../components/Product";
import "./Home.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Reviews from "../../components/Reviews";

function Home() {
  const [getdata, setdata] = useState([
    {
      id: 1,
      img: "./images/don-white.png",
      title: "ADIDAS",
      desc: 'Don Issue 3 "White Camou"',
      price: 3650,
    },
    {
      id: 2,
      img: "./images/dame-mint.jpg",
      title: "ADIDAS",
      desc: 'Dame 7 Extply "Black Clear Mint"',
      price: 3650,
    },
    {
      id: 3,
      img: "./images/nike-white.jpg",
      title: "NIKE",
      desc: " Nike Airmax Impact 2",
      price: 3650,
    },
    {
      id: 4,
      img: "./images/nike-immo.jpg",
      title: "NIKE",
      desc: " Nike Giannis Immortality 2",
      price: 3650,
    },
    {
      id: 5,
      img: "./images/cons-jam.jpg",
      title: "CONVERSE",
      desc: ' Converse BB Jet "NBA Jam"',
      price: 3780,
    },
    {
      id: 6,
      img: "./images/nb-pink.png",
      title: "NEW BALANCE",
      desc: ' New Balance 2WXYZ "Pink"',
      price: 3780,
    },
    {
      id: 7,
      img: "./images/nb-yellow.png",
      title: "NEW BALANCE",
      desc: 'New Balance 2WXYZ "Yellow"',
      price: 3780,
    },
    {
      id: 8,
      img: "./images/chuck-70s.png",
      title: "CONVERSE",
      desc: "Converse Chuck Taylor 70s",
      price: 3780,
    },
  ]);

  return (
    <div>
      <Banner />
      <div className="shopify-section">
        <section className="container new-arrivals">
          <div className="new-arrivals_wrap py-5 px-3">
            <h2 className="section-heading mb-3">New Arrivals</h2>
            <div className="new-arrivals_products">
              <div className="row">
                {getdata.map((data, index) => {
                  return <Product data={data} index={index} />;
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Reviews />
    </div>
  );
}

export default Home;
