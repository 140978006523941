import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/collapse";
import { useState } from "react";
import "./Dashboard.css";
import { Api } from "../Api";

function Dashboard() {
  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };
  const [active, setActive] = useState("product");
  const [data, setData] = useState([]);

  const getProducts = () => {
    fetch(`${Api.ENDPOINT}/api/product`, {
      method: "GET",
      mode: Api.MODE,
      headers: Api.HEADERS,
    })
      .then((data) => data.json())
      .then((data) => {
        setActive("product");
        setData(data);
      })
      .catch((error) => console.error(error.message));
  };

  const onAddProd = (e) => {
    e.preventDefault();
    if (!e) return;
    const data = new FormData();
    data.brand = e.target["brand"].value;
    data.description = e.target["description"].value;
    data.sizes = e.target["sizes"].value;
    data.price = e.target["price"].value;
    data.image = e.target["image"].value;

    fetch(`${Api.ENDPOINT}/api/product`, {
      method: "POST",
      mode: Api.MODE,
      headers: Api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        getProducts();
      })
      .catch((error) => console.error(error.message));
  };

  return (
    <div className="container-fluid bg-secondary min-vh-100 ">
      <div className="row ">
        {toggle && (
          <div className="col-4 col-md-2 bg-white vh-100 position-fixed"></div>
        )}
        {toggle && (
          <div className="col-4 col-md-2 bg-white vh-100 px-0">
            <div className="bg-white sidebar p-0">
              <div className="list-group list-group-flush">
                <a style={{ cursor: "pointer" }} className="list-group-item ">
                  <i className="bi bi-house fs-5 me-3"></i> <span>Orders</span>
                </a>
                <a
                  onClick={() => getProducts()}
                  style={{ cursor: "pointer" }}
                  className="list-group-item"
                >
                  <i className="bi bi-table fs-5 me-3"></i>
                  <span>Products</span>
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="col">
          <div className="px-3">
            <nav className="navbar navbar-expand-sm navbar-dark bg-transparent">
              <i
                className="navbar-brand bi bi-justify-left fs-4"
                onClick={Toggle}
              ></i>
              <button
                className="navbar-toggler d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavId"
                aria-controls="collapsibleNavId"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-justify"></i>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavId">
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"
                      id="dropdownId"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sole Support
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownId">
                      <a className="dropdown-item" href="#">
                        Profile
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="container-fluid">
              <div className="row g-3 my-2">
                <div className="col-md-3 p-1">
                  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                    <div>
                      <h3 className="fs-2">230</h3>
                      <p className="fs-5">Products</p>
                    </div>
                    <i className="bi bi-cart-plus p-3 fs-1"></i>
                  </div>
                </div>
                <div className="col-md-3 p-1">
                  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                    <div>
                      <h3 className="fs-2">2450</h3>
                      <p className="fs-5">Sales</p>
                    </div>
                    <i className="bi bi-currency-dollar p-3 fs-1"></i>
                  </div>
                </div>
                <div className="col-md-3 p-1">
                  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                    <div>
                      <h3 className="fs-2">2250</h3>
                      <p className="fs-5">Delivery</p>
                    </div>
                    <i className="bi bi-truck p-3 fs-1"></i>
                  </div>
                </div>
                <div className="col-md-3 p-1">
                  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                    <div>
                      <h3 className="fs-2">20%</h3>
                      <p className="fs-5">Increase</p>
                    </div>
                    <i className="bi bi-graph-up-arrow p-3 fs-1"></i>
                  </div>
                </div>
              </div>
            </div>
            <table class="table caption-top bg-white rounded mt-2">
              <caption className="text-white fs-4">Product</caption>

              <thead>
                <tr>
                  <th scope="col">Brand</th> <th scope="col">Description</th>
                  <th scope="col">Sizes</th> <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return active === "product" ? (
                    <tr>
                      <th scope="row">{item.brand}</th>
                      <td>{item.description}</td> <td>{item.sizes}</td>
                      <td>{item.price}</td> <td>{item.image}</td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
            <div>
              <button
                type="button"
                className="login button button-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Add Product
              </button>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content" style={{ width: "720px" }}>
                    <div className="modal-header">
                      <form
                        className="reg"
                        id="addprod"
                        name="addprod"
                        onSubmit={onAddProd}
                      >
                        <div className="mb-3">
                          <label htmlFor="fname" className="form-label">
                            Brand
                          </label>
                          <input
                            type="name"
                            className="form-control email"
                            id="brand"
                            name="brand"
                            placeholder="Brand"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="lname" className="form-label">
                            Description
                          </label>
                          <input
                            type="name"
                            className="form-control email"
                            id="description"
                            name="description"
                            placeholder="Description"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Sizes
                          </label>
                          <input
                            type="name"
                            className="form-control email"
                            id="sizes"
                            name="sizes"
                            placeholder="Sizes"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Price
                          </label>
                          <input
                            type="name"
                            className="form-control email"
                            id="price"
                            name="price"
                            placeholder="Price"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            Image
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="image"
                            name="image"
                          />
                        </div>
                        <button
                          type="submit"
                          className="login button button-primary"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
