import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const styles = {
  footerbody: { backgroundColor: "rgb(0, 0, 0)" },
};

const Footer = () => {
  return (
    <footer>
      <div className="footer-section" style={styles.footerbody}>
        <section className="container">
          <div className="row px-3">
            <div class="row text-center d-flex justify-content-center pt-5">
              <div class="col-md-2">
                <h6 class="text-uppercase font-weight-bold">
                  <Link to="/faqs">
                    <a href="#!" class="text-white">
                      FAQS
                    </a>
                  </Link>
                </h6>
              </div>

              <div class="col-md-2">
                <h6 class="text-uppercase font-weight-bold">
                  <Link to="/contactus">
                    <a href="#!" class="text-white">
                      Contact Us
                    </a>
                  </Link>
                </h6>
              </div>

              <div class="col-md-2">
                <h6 class="text-uppercase font-weight-bold">
                  <Link to="/AboutUs">
                    <a href="#!" class="text-white">
                      About Us
                    </a>
                  </Link>
                </h6>
              </div>

              <div class="col-md-2">
                <h6 class="text-uppercase font-weight-bold">
                  <a href="#!" class="text-white">
                    Store Locator
                  </a>
                </h6>
              </div>
            </div>

            <div className="column mt-1 text-center">
              <i className="text-white fa-regular fa-copyright me-5">
                Sole Support
              </i>
              <button
                href="https://www.facebook.com/solesupportph"
                className="p-4"
              >
                <ion-icon className="logo-icon" name="logo-facebook" />
              </button>
              <button
                href="https://www.facebook.com/messages/t/108327300957463"
                className="p-4"
              >
                <i className="logo-icon fa-brands fa-facebook-messenger" />
              </button>
              <button href="#" className="p-4">
                <ion-icon className="logo-icon" name="logo-instagram" />
              </button>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};
export default Footer;
